import i18n from '@/plugins/i18n';

export default function (role = 'user',studentProgram={}) {
    let menus = [
        {
            title: i18n.t('general_information'),
            title_short: i18n.t('general'),
            slug: 'general_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('identity_information'),
            title_short: i18n.t('identity'),
            slug: 'identity_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('foreign_student'),
            title_short: i18n.t('foreign_student'),
            slug: 'foreign_student_credentials',
            permission: 'studentprogram_show',
            show: studentProgram.nationality_code != 'TR',
            showStudent: true
        },
        {
            title: i18n.t('address_information'),
            title_short: i18n.t('address'),
            slug: 'address_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('family_information'),
            title_short: i18n.t('family'),
            slug: 'family_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('military_information'),
            title_short: i18n.t('military'),
            slug: 'military_information',
            permission: 'studentprogram_show',
            show: studentProgram.gender=='Erkek',
            showStudent: true
        },
        {
            title: i18n.t('health_information'),
            title_short: i18n.t('health'),
            slug: 'health_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('university_informations'),
            title_short: i18n.t('university'),
            slug: 'university_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('contact_information'),
            title_short: i18n.t('contact'),
            slug: 'contact_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('score_information'),
            title_short: i18n.t('score'),
            slug: 'score_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('education_information'),
            title_short: i18n.t('education'),
            slug: 'student_education',
            permission: 'studenteducation_index',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('program_vertical'),
            title_short: i18n.t('program_vertical'),
            slug: 'undergraduate_vertical_transfer_information',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('documents'),
            title_short: i18n.t('documents'),
            slug: 'documents',
            permission: 'studentprogram_ebysdocument',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('missing_documents'),
            title_short: i18n.t('missing_documents'),
            slug: 'missing_document',
            permission: 'studentprogram_show',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('discipline_process'),
            title_short: i18n.t('discipline_process'),
            slug: 'discipline_process',
            permission: 'discipline_studentprogramdisciplines',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('notes'),
            title_short: i18n.t('notes'),
            slug: 'notes',
            permission: 'studentnote_index',
            show: true,
            showStudent: false
        },
        {
            title: i18n.t('graduate_requirement_info'),
            title_short: i18n.t('graduate_requirement_info_short'),
            slug: 'graduate_requirement_info',
            permission: 'studentprogram_graduationrequirementinformationupdate',
            show: true,
            showStudent: true
        },
        {
            title: i18n.t('yoksis_permissions'),
            title_short: i18n.t('yoksis_permissions'),
            slug: 'yoksis_permissions',
            permission: 'yoksisservice_permitlist',
            show: true,
            showStudent: false
        }
    ];

    return menus
}


